<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <v-card class="card">
      <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-card-title class="heading justify-center">IN OUT REPORT</v-card-title>
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <v-col cols="12" sm="3" md="3">
              <label class="add-text">Organization</label>
              <v-autocomplete v-model="selected_org" placeholder="Select Organization" :items="org_list"
              item-text="name" item-value="id" outlined class="text" dense @change="onLoad()"></v-autocomplete>
            </v-col>
            <v-col>
              <label class="add-text">Program</label>
              <v-autocomplete v-model="program" placeholder="Select Program" :items="program_data" item-text="name"
                item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
            </v-col>
            <v-col>
              <label class="add-text">Usertype</label>
              <v-autocomplete v-model="utype" placeholder="Select Usertype" :items="utype_list" item-text="name"
                item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
            </v-col>
            <v-col>
              <label>Start Date</label>

              <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                full-width max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field class="text" v-model="startDate" placeholder="Select Start Date" append-icon="event"
                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title @input="fromDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label>End Date</label>

              <v-menu v-model="fromDateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y
                full-width max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field class="text" v-model="endDate" placeholder="Select End Date" append-icon="event"
                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title @input="fromDateMenu1 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="dailyData1">Fetch Data</v-btn>
            </center>
          </div>
        </div>
        </div>

        <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
        <v-data-table :headers="headers" :search="search" :items="tabledata" class="elevation-5 ma-2">
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>In Out Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <download-excel class="ma-3" v-if="tabledata.length != 0" color="primary" dark :data="tabledata"
                :fields="fields" worksheet="My Worksheet" name="member_wise_library_utilization_Report.xls">
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>

        </v-data-table>
      </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({

    dept: null,
    fromDateMenu1: false,
    usertype: null,
    valid: false,
    init_loading: false,
    loader: false,
    userid: "",
    bookitemid: "",
    selected: [],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Reports',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'IN OUT REPORT',
        disabled: true,
        href: '',
      },
    ],
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    org: "",
    dialog: false,
    fromDateMenu: false,
    fromDateVal: [],
    startDate: "",
    endDate: "",
    dailyData: [],
    program_data: [],
    usertype_data: [],
    minDate: "2020-01-05",
    maxDate: new Date(),
    program: "",
    no_of_days: 30,
    fields: {
      PRN: "PRN",
      Name: "name",
      Program: "program",
      Division: "division",
      Usertype: "usertype",
      in_time: "in_time",
      out_time: "out_time"
    },
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "PRN",
        align: "left",
        sortable: true,
        value: "PRN",
      },
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: "Program",
        align: "left",
        sortable: true,
        value: "program",
      },
      {
        text: "Division",
        align: "left",
        sortable: true,
        value: "division",
      },
      {
        text: "UserType",
        align: "left",
        sortable: true,
        value: "usertype",
      },
      {
        text: "In Time",
        align: "left",
        sortable: true,
        value: "in_time",
      },
      {
        text: "Out Time",
        align: "left",
        sortable: true,
        value: "out_time",
      },
    ],
    selected_org: '',
    org_list: [],
  }),
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
    this.endDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.init();
  },
  methods: {
    dailyData1() {
      if(!this.selected_org)
      {
          this.showSnackbar("#b71c1c", "Please Select Organization");
          return;
      }
      this.loader = true;
      this.tabledata = [];
      const data = {
        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
        program_id: this.program,
        flag: true,
        ut_id: this.utype,
        org: this.selected_org,
      };
      axios
        .post("/MovementRegister/getInOutData", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader = false;
            this.tabledata = res.data.data;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => { });
    },
    init()
    {
        this.init_loading = true;
        axios
            .post("/ImportData/getOrgList")
            .then((res) => {
                if (res.data.msg == "200") {
                    this.org_list=res.data.org_list;
                    this.selected_org=res.data.org;
                    this.onLoad();
                }
            })
    },
    onLoad() {
      if(!this.selected_org)
      {
          this.showSnackbar("#b71c1c", "Please Select Organization");
          return;
      }
      var params={"org": this.selected_org};
      this.init_loading = true;
      axios
        .post("/Librarian/getERPDeprtmentData", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.program_data = res.data.program_data;
            this.program_data.push({ name: "ALL", id: "ALL" });
            this.program = "ALL";
            this.utype_list = res.data.utype_list;
            if (res.data.utype_list)
              this.utype = this.utype_list[0].id;
            const today = new Date();
            const previousdate = new Date();
            previousdate.setDate(today.getDate() - this.no_of_days);
            this.startDate = moment(String(previousdate)).format("YYYY-MM-DD");
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
